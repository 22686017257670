<template>
  <div>
    <v-app id="inspire">
      <v-navigation-drawer v-model="drawer" clipped app width="220">
        <v-card
          class="mx-auto hidden-md-and-up"
          width="220"
          outlined
          color="primary"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-subtitle class="white--text">
                <div>
                  <v-icon dense dark>mdi-account</v-icon>
                  <span class="pl-2">
                    {{ $store.state.auth.user.FirstName }}
                  </span>
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
        <v-list dense>
          <v-list-group
            v-for="(item1, i) in menus"
            :key="i"
            v-model="item1.active"
            :prepend-icon="item1.active ? item1.icon : item1['icon-alt']"
            append-icon=""
            @click="collapeMenus(i, item1.menus)"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item1.text }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-group
              sub-group
              v-for="(item2, i) in item1.menuLv2"
              :key="i"
              v-model="item2.active"
              :prepend-icon="item2.active ? item2.icon : item2['icon-alt']"
              append-icon=""
              @click="collapeMenus(i, item2.menuLv2)"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item2.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <div class="pl-4">
                <v-list-group
                  color="secondary"
                  sub-group
                  v-for="(item3, i) in item2.menuLv3"
                  :key="i"
                  v-model="item3.active"
                  :prepend-icon="item3.active ? item3.icon : item3['icon-alt']"
                  append-icon=""
                  :to="item3.path"
                  @click="collapeMenus(i, item2.menuLv3)"
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item3.text }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-group>
              </div>
            </v-list-group>
          </v-list-group>
        </v-list>
        <v-footer absolute padless class="secondary secondary--text">
          <v-col class="text-center" cols="12">
            {{ new Date().getFullYear() }} — <strong>Sanupsanoon</strong>
          </v-col>
        </v-footer>
      </v-navigation-drawer>

      <v-app-bar clipped-left app color="primary" dark>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title style="width: 220" class="ml-0 pl-4">
          <span>Back Office</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text dark v-bind="attrs" v-on="on">
              <v-icon class="pr-2">mdi-account</v-icon>
              <span>
                {{ $store.state.auth.user.FirstName }}
              </span>
              <v-icon>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="logout">
              <v-list-item-action>
                <v-icon color="primary">mdi-logout</v-icon>
              </v-list-item-action>
              <v-list-item-title>
                <span class="primary--text">ออกจากระบบ</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>

      <v-main>
        <v-container>
          <transition name="fade" mode="out-in">
            <router-view></router-view>
          </transition>
        </v-container>
      </v-main>
      <v-footer absolute padless class="secondary white--text">
        <v-col class="text-center" cols="12">
          {{ new Date().getFullYear() }} — <strong>Sanupsanoon</strong>
        </v-col>
      </v-footer>
    </v-app>
  </div>
</template>

<script>
// import { mapState, mapActions } from "vuex";
import router from "@/router";
import { mapState, mapActions } from "vuex";

export default {
  name: "Main",
  data: () => ({
    dialog: false,
    drawer: null,
    menus: null,
  }),
  mounted() {
    this.actCheckAuth();
    this.actParameterGets();
    this.genMenu();
  },
  computed:{
    ...mapState("auth", ["user"]),
  },
  methods: {
    ...mapActions("auth", ["actCheckAuth", "actLogout"]),
    ...mapActions("settings", ["actParameterGets"]),

    logout() {
      this.actLogout();
    },
    collapeMenus(val, items) {
      if (items) {
        let parent = items[val].parent;
        this.menus.forEach((e) => {
          e.menuLv2.forEach((e) => {
            if (e.text != parent) {
              e.active = false;
            }
            e.menuLv3.forEach((e) => {
              e.active = false;
            });
          });
        });
        // console.log(items);
        items.forEach((x, i) => {
          if (val != i) x.active = false;
          router.push(items[val].path).catch(() => {});
        });
      }
    },
    genMenu() {
      if (this.user.IsAdmin == 1) {
        this.menus = [
          {
            icon: "mdi-chevron-up",
            "icon-alt": "mdi-cog",
            text: "การตั้งค่า",
            active: false,
            menuLv2: [
              {
                icon: "mdi-chevron-down",
                "icon-alt": "mdi-chevron-down",
                text: "สิทธิ์",
                active: false,
                parent: "การตั้งค่า",
                menuLv3: [
                  {
                    icon: "mdi-chevron-double-left",
                    text: "ตั้งค่าสิทธิ์",
                    "icon-alt": " mdi-chevron-left",
                    path: "/SettingPermission",
                    active: false,
                    parent: "สิทธิ์",
                  },
                  {
                    icon: "mdi-chevron-double-left",
                    text: "ตั้งค่าระบบ",
                    "icon-alt": " mdi-chevron-left",
                    path: "/SettingParameter",
                    active: false,
                    parent: "สิทธิ์",
                  },
                  {
                    icon: "mdi-chevron-double-left",
                    text: "ตั้งค่าเลขที่เอกสาร",
                    "icon-alt": " mdi-chevron-left",
                    path: "/SettingSysNumberFormat",
                    active: false,
                    parent: "สิทธิ์",
                  },
                  {
                    icon: "mdi-chevron-double-left",
                    text: "ตั้งค่าเครื่องปริ้น",
                    "icon-alt": " mdi-chevron-left",
                    path: "/SettingPrinter",
                    active: false,
                    parent: "สิทธิ์",
                  },
                ],
              },
              {
                icon: "mdi-chevron-down",
                "icon-alt": "mdi-chevron-down",
                text: "ผู้ใช้",
                active: false,
                menuLv3: [
                  {
                    icon: "mdi-chevron-double-left",
                    text: "ตั้งค่าผู้ใช้",
                    "icon-alt": " mdi-chevron-left",
                    path: "/SettingUser",
                    active: false,
                    parent: "ผู้ใช้",
                  },
                ],
              },
              {
                icon: "mdi-chevron-down",
                "icon-alt": "mdi-chevron-down",
                text: "สินค้า",
                active: false,
                menuLv3: [
                  {
                    icon: "mdi-chevron-double-left",
                    text: "ตั้งค่าวัตถุดิบ",
                    "icon-alt": " mdi-chevron-left",
                    path: "/SettingRawMaterial",
                    active: false,
                    parent: "สินค้า",
                  },
                  {
                    icon: "mdi-chevron-double-left",
                    text: "ตั้งค่าเมนู",
                    "icon-alt": " mdi-chevron-left",
                    path: "/SettingFoodMenu",
                    active: false,
                    parent: "สินค้า",
                  },
                  {
                    icon: "mdi-chevron-double-left",
                    text: "ตั้งค่าประเภทเมนู",
                    "icon-alt": " mdi-chevron-left",
                    path: "/SettingMenuType",
                    active: false,
                    parent: "สินค้า",
                  },
                  {
                    icon: "mdi-chevron-double-left",
                    text: "ตั้งค่าหน่วย",
                    "icon-alt": " mdi-chevron-left",
                    path: "/SettingUnit",
                    active: false,
                    parent: "สินค้า",
                  },
                  {
                    icon: "mdi-chevron-double-left",
                    text: "ตั้งค่าคลังสินค้า",
                    "icon-alt": " mdi-chevron-left",
                    path: "/SettingWarehouse",
                    active: false,
                    parent: "สินค้า",
                  },
                  {
                    icon: "mdi-chevron-double-left",
                    text: "ตั้งค่าผู้จำหน่าย",
                    "icon-alt": " mdi-chevron-left",
                    path: "/SettingSupplier",
                    active: false,
                    parent: "สินค้า",
                  },
                ],
              },
              {
                icon: "mdi-chevron-down",
                "icon-alt": "mdi-chevron-down",
                text: "หน้าร้าน",
                active: false,
                menuLv3: [
                  {
                    icon: "mdi-chevron-double-left",
                    text: "ตั้งค่าพื้นที่",
                    "icon-alt": " mdi-chevron-left",
                    path: "/SettingZone",
                    active: false,
                    parent: "หน้าร้าน",
                  },
                  {
                    icon: "mdi-chevron-double-left",
                    text: "ตั้งค่าประเภทโต๊ะ",
                    "icon-alt": " mdi-chevron-left",
                    path: "/SettingTBLType",
                    active: false,
                    parent: "หน้าร้าน",
                  },
                  {
                    icon: "mdi-chevron-double-left",
                    text: "ตั้งค่าโต๊ะ",
                    "icon-alt": " mdi-chevron-left",
                    path: "/SettingTbl",
                    active: false,
                    parent: "หน้าร้าน",
                  },
                  {
                    icon: "mdi-chevron-double-left",
                    text: "ตั้งค่าลูกค้า",
                    "icon-alt": " mdi-chevron-left",
                    path: "/SettingCust",
                    active: false,
                    parent: "หน้าร้าน",
                  },
                ],
              },
            ],
          },
          {
            icon: "mdi-chevron-up",
            "icon-alt": "mdi-format-list-checks",
            text: "รายการ",
            active: false,
            menuLv2: [
              {
                icon: "mdi-chevron-double-left",
                text: "หน้าร้าน",
                "icon-alt": " mdi-chevron-left",
                active: false,
                menuLv3: [
                  {
                    icon: "mdi-chevron-double-left",
                    text: "รายการบิล",
                    "icon-alt": " mdi-chevron-left",
                    path: "/OrderTable",
                    active: false,
                    parent: "หน้าร้าน",
                  },
                ],
              },
              {
                icon: "mdi-chevron-double-left",
                text: "หลังร้าน",
                "icon-alt": " mdi-chevron-left",
                active: false,
                menuLv3: [
                  {
                    icon: "mdi-chevron-double-left",
                    text: "รายการรับเข้า",
                    "icon-alt": " mdi-chevron-left",
                    path: "/ReceiveRecord",
                    active: false,
                    parent: "หลังร้าน",
                  },
                ],
              },
            ],
          },
        ];
      } else {
        this.menus = [
          {
            icon: "mdi-chevron-up",
            "icon-alt": "mdi-cog",
            text: "การตั้งค่า",
            active: false,
            menuLv2: [
              {
                icon: "mdi-chevron-down",
                "icon-alt": "mdi-chevron-down",
                text: "สิทธิ์",
                active: false,
                parent: "การตั้งค่า",
                menuLv3: [
                  {
                    icon: "mdi-chevron-double-left",
                    text: "ตั้งค่าสิทธิ์",
                    "icon-alt": " mdi-chevron-left",
                    path: "/SettingPermission",
                    active: false,
                    parent: "สิทธิ์",
                  },
                  {
                    icon: "mdi-chevron-double-left",
                    text: "ตั้งค่าระบบ",
                    "icon-alt": " mdi-chevron-left",
                    path: "/SettingParameter",
                    active: false,
                    parent: "สิทธิ์",
                  },
                  {
                    icon: "mdi-chevron-double-left",
                    text: "ตั้งค่าเลขที่เอกสาร",
                    "icon-alt": " mdi-chevron-left",
                    path: "/SettingSysNumberFormat",
                    active: false,
                    parent: "สิทธิ์",
                  },
                  {
                    icon: "mdi-chevron-double-left",
                    text: "ตั้งค่าเครื่องปริ้น",
                    "icon-alt": " mdi-chevron-left",
                    path: "/SettingPrinter",
                    active: false,
                    parent: "สิทธิ์",
                  },
                ],
              },
              {
                icon: "mdi-chevron-down",
                "icon-alt": "mdi-chevron-down",
                text: "ผู้ใช้",
                active: false,
                menuLv3: [
                  {
                    icon: "mdi-chevron-double-left",
                    text: "ตั้งค่าผู้ใช้",
                    "icon-alt": " mdi-chevron-left",
                    path: "/SettingUser",
                    active: false,
                    parent: "ผู้ใช้",
                  },
                ],
              },
              {
                icon: "mdi-chevron-down",
                "icon-alt": "mdi-chevron-down",
                text: "สินค้า",
                active: false,
                menuLv3: [
                  {
                    icon: "mdi-chevron-double-left",
                    text: "ตั้งค่าวัตถุดิบ",
                    "icon-alt": " mdi-chevron-left",
                    path: "/SettingRawMaterial",
                    active: false,
                    parent: "สินค้า",
                  },
                  {
                    icon: "mdi-chevron-double-left",
                    text: "ตั้งค่าเมนู",
                    "icon-alt": " mdi-chevron-left",
                    path: "/SettingFoodMenu",
                    active: false,
                    parent: "สินค้า",
                  },
                  {
                    icon: "mdi-chevron-double-left",
                    text: "ตั้งค่าประเภทเมนู",
                    "icon-alt": " mdi-chevron-left",
                    path: "/SettingMenuType",
                    active: false,
                    parent: "สินค้า",
                  },
                  {
                    icon: "mdi-chevron-double-left",
                    text: "ตั้งค่าหน่วย",
                    "icon-alt": " mdi-chevron-left",
                    path: "/SettingUnit",
                    active: false,
                    parent: "สินค้า",
                  },
                  {
                    icon: "mdi-chevron-double-left",
                    text: "ตั้งค่าคลังสินค้า",
                    "icon-alt": " mdi-chevron-left",
                    path: "/SettingWarehouse",
                    active: false,
                    parent: "สินค้า",
                  },
                  {
                    icon: "mdi-chevron-double-left",
                    text: "ตั้งค่าผู้จำหน่าย",
                    "icon-alt": " mdi-chevron-left",
                    path: "/SettingSupplier",
                    active: false,
                    parent: "สินค้า",
                  },
                ],
              },
              {
                icon: "mdi-chevron-down",
                "icon-alt": "mdi-chevron-down",
                text: "หน้าร้าน",
                active: false,
                menuLv3: [
                  {
                    icon: "mdi-chevron-double-left",
                    text: "ตั้งค่าพื้นที่",
                    "icon-alt": " mdi-chevron-left",
                    path: "/SettingZone",
                    active: false,
                    parent: "หน้าร้าน",
                  },
                  {
                    icon: "mdi-chevron-double-left",
                    text: "ตั้งค่าประเภทโต๊ะ",
                    "icon-alt": " mdi-chevron-left",
                    path: "/SettingTBLType",
                    active: false,
                    parent: "หน้าร้าน",
                  },
                  {
                    icon: "mdi-chevron-double-left",
                    text: "ตั้งค่าโต๊ะ",
                    "icon-alt": " mdi-chevron-left",
                    path: "/SettingTbl",
                    active: false,
                    parent: "หน้าร้าน",
                  },
                  {
                    icon: "mdi-chevron-double-left",
                    text: "ตั้งค่าลูกค้า",
                    "icon-alt": " mdi-chevron-left",
                    path: "/SettingCust",
                    active: false,
                    parent: "หน้าร้าน",
                  },
                ],
              },
            ],
          },
          {
            icon: "mdi-chevron-up",
            "icon-alt": "mdi-format-list-checks",
            text: "รายการ",
            active: false,
            menuLv2: [
              {
                icon: "mdi-chevron-double-left",
                text: "หลังร้าน",
                "icon-alt": " mdi-chevron-left",
                active: false,
                menuLv3: [
                  {
                    icon: "mdi-chevron-double-left",
                    text: "รายการรับเข้า",
                    "icon-alt": " mdi-chevron-left",
                    path: "/ReceiveRecord",
                    active: false,
                    parent: "หลังร้าน",
                  },
                ],
              },
            ],
          },
        ];
      }
    },
  },
  watch: {
    // actCheckAuth() {},
    // user() {
    //   console.log("123546");
    //   this.actCheckAuth();
    // }
  },
};
</script>

<style scoped>
.v-list-item__title {
  font-size: 14px !important;
}
</style>
